export default {
  name: "TLoanInstallmentSimulationRestructuration",
  data() {
    return {
      dataForm: {
        request: {
          restructureType: "",
          loanAccountId: "",
          loanAccountNumber: 0,
          cifIdName: "",
          officeName: "",
          nominalLoan: 0,
          restructPrincipal: 0,
          postponePrincipal: 0,
          installmentMargin: 0,
          rateMarginTotal: 0,
          unpaid: 0,
          remainPeriod: 0,
          flatPrincipal: 0,
          dueDate: "",
          installmentPrincipal: 0,
          rescheduleType: 0,
          period: 0,
          rescheduleType: 0,
        },
        response: {
          principal: 0,
          period: 0,
          principalRemain: 0,
          instalmentRestrict: 0,
          deferredPrincipal: 0,
        },
      },
      options: {
        restructureType: [],
        rescheduleType: [
          { text: "Pengurangan Pokok", value: 1 },
          { text: "Update Kolektabilitas", value: 2 },
        ],
      },
      table: {
        data: {
          loanInstallmentSimulationRestructuration: [],
        },
      },
      property: {
        listElement: {
          loanInstallmentSimulationRestructuration: {
            downloading: false,
            message: "",
          },
        },
        animation: {
          generateInstallmentRescheduleSimulation: {
            isLoading: false,
          },
        },
      },
    };
  },
  methods: {
    handleChangeMarginRestrk() {
      if (this.dataForm.request.restructPrincipal === 0) {
        this.dataForm.request.postponePrincipal =
          this.dataForm.request.flatPrincipal * this.dataForm.request.unpaid;
      } else if (this.dataForm.request.restructPrincipal > 0) {
        this.dataForm.request.postponePrincipal =
          this.dataForm.request.flatPrincipal -
          this.dataForm.request.restructPrincipal;
      }
    },
    handleSearchLoanAccountNumber() {
      this.$buefy.dialog.prompt({
        title: "Cari Angsuran",
        message: `Masukkan Nomor Kontrak`,
        type: "is-success",
        inputAttrs: {
          placeholder: "Nomor Kontrak",
        },
        trapFocus: true,
        cancelText: `Batal`,
        confirmText: "Cari",
        closeOnConfirm: true,
        onConfirm: async (loanAccountNumber, { close }) => {
          if (loanAccountNumber) {
            try {
              const resp = await this.$store.dispatch({
                type: "GET_DATA_FIND_BY",
                endPoint: "loan",
                reqUrl:
                  "loan-installment/findByLoanAccountNumber/" +
                  loanAccountNumber,
              });
              if (resp.data.code === "SUCCESS") {
                close();
                this.handleClearFormRequest();
                setTimeout(() => {
                  console.log(resp);
                  this.dataForm.request.installmentMargin =
                    resp.data.data.installmentMargin;
                  this.dataForm.request.loanAccountNumber = loanAccountNumber;
                  this.dataForm.request.cifIdName = resp.data.data.cifIdName;
                  this.dataForm.request.officeName = resp.data.data.officeName;
                  this.dataForm.request.nominalLoan =
                    resp.data.data.nominalLoan;
                  this.dataForm.request.timePeriod = resp.data.data.timePeriod;
                  this.dataForm.request.rateMarginTotal =
                    resp.data.data.rateMarginTotal;
                  this.dataForm.request.installmentPrincipal =
                    resp.data.data.installmentPrincipal;
                  this.dataForm.request.flatPrincipal =
                    resp.data.data.flatPrincipal;
                  this.dataForm.request.unpaid = resp.data.data.unpaid;
                  this.dataForm.request.loanAccountId =
                    resp.data.data.loanAccountId;
                  this.dataForm.request.remainPeriod = resp.data.data.unpaid;
                  this.dataForm.request.postponePrincipal =
                    resp.data.data.flatPrincipal * resp.data.data.unpaid;
                }, 500);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }
        },
      });
    },
    handleClearFormRequest() {
      this.dataForm.request.loanAccountNumber = "";
      this.dataForm.request.cifIdName = "";
      this.dataForm.request.officeName = "";
      this.dataForm.request.nominalLoan = 0;
      this.dataForm.request.restructPrincipal = 0;
      this.dataForm.request.postponePrincipal = 0;
      this.dataForm.request.rateMarginTotal = 0;
      this.dataForm.request.unpaid = 0;
      this.dataForm.request.remainPeriod = 0;
      this.dataForm.request.flatPrincipal = 0;
      this.dataForm.request.installmentPrincipal = 0;
      this.dataForm.request.period = 0;
      this.dataForm.request.timePeriod = 0;
      this.dataForm.request.installmentMargin = 0;
      this.dataForm.request.rescheduleType = 0;
      this.handleClearFormResponse();
    },
    handleChargingOptionsComponent() {
      this.options.restructureType.push(
        { value: "", text: "-- Pilih --" },
        { value: "RESCHEDULE", text: "Reschedule" },
        { value: "RESTRUCTURE", text: "Restrukturisasi 2" }
      );
    },
    handleDefaultMessageTable() {
      this.property.listElement.loanInstallmentSimulationRestructuration.message =
        "Belum Ada Data Yang Di Proses";
    },
    handleClearFormResponse() {
      this.dataForm.response.principal = "";
      this.dataForm.response.period = "";
      this.dataForm.response.principalRemain = "";
      this.dataForm.response.installmentPrincipal = "";
      this.dataForm.response.instalmentRestrict = "";
      this.dataForm.response.deferredPrincipal = "";
      this.table.data.loanInstallmentSimulationRestructuration = [];
    },
    handleCheckingRestructureType() {
      switch (this.dataForm.request.restructureType) {
        case "RESCHEDULE":
          this.handleGenerateInstallmentRescheduleSimulation();
          break;

        case "RESTRUCTURE":
          this.handleGenerateInstallmentRestructureSimulation();
          break;

        default:
          return;
      }
    },
    onChangeRescheduleType() {
      this.dataForm.request.restructPrincipal = 0;
    },
    async handleGenerateInstallmentRestructureSimulation() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.property.animation.generateInstallmentRescheduleSimulation.isLoading = true;
        const payload = {
          loanAccountId: this.dataForm.request.loanAccountId,
          restructureType: this.dataForm.request.restructureType,
          period: this.dataForm.request.period,
        };
        try {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "loan",
            reqUrl: "loan-installment/generate-reschedule-simulation",
            payload: payload,
          });
          if (resp.data.code === "SUCCESS") {
            this.$buefy.toast.open(this.$NotificationUtils.success);
            this.handleClearFormResponse();
            setTimeout(() => {
              this.dataForm.response.principal = resp.data.data.principal;
              this.dataForm.response.period = resp.data.data.period;
              this.dataForm.response.principalRemain =
                resp.data.data.principalRemain;
              this.dataForm.response.installmentPrincipal =
                resp.data.data.installmentPrincipal;
              this.dataForm.response.instalmentRestrict =
                resp.data.data.instalmentRestrict;
              this.dataForm.response.deferredPrincipal =
                resp.data.data.deferredPrincipal;
              this.table.data.loanInstallmentSimulationRestructuration =
                resp.data.data.detailResponseDtoList;
            }, 1000);
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          setTimeout(() => {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1000);
        } finally {
          setTimeout(() => {
            this.property.animation.generateInstallmentRescheduleSimulation.isLoading = false;
          }, 1000);
        }
      }
    },
    async handleGenerateInstallmentRescheduleSimulation() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.property.animation.generateInstallmentRescheduleSimulation.isLoading = true;
        const payload = {
          loanAccountId: this.dataForm.request.loanAccountId,
          restructureType: this.dataForm.request.restructureType,
          restructPrincipal: this.dataForm.request.restructPrincipal
            ? this.dataForm.request.restructPrincipal
            : 0,
          rescheduleType: this.dataForm.request.rescheduleType,
        };
        try {
          const resp = await this.$store.dispatch({
            type: "POST_DATA",
            endPoint: "loan",
            reqUrl: "loan-installment/generate-reschedule-simulation",
            payload: payload,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open(this.$NotificationUtils.success);
              this.handleClearFormResponse();
              setTimeout(() => {
                this.dataForm.response.principal = resp.data.data.principal;
                this.dataForm.response.period = resp.data.data.period;
                this.dataForm.response.principalRemain =
                  resp.data.data.principalRemain;
                this.dataForm.response.installmentPrincipal =
                  resp.data.data.installmentPrincipal;
                this.dataForm.response.instalmentRestrict =
                  resp.data.data.instalmentRestrict;
                this.dataForm.response.deferredPrincipal =
                  resp.data.data.deferredPrincipal;
                this.table.data.loanInstallmentSimulationRestructuration =
                  resp.data.data.detailResponseDtoList;
              }, 1000);
            } else {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: `${resp.data.message}`,
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          }, 1000);
        } catch (error) {
          setTimeout(() => {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1000);
        } finally {
          setTimeout(() => {
            this.property.animation.generateInstallmentRescheduleSimulation.isLoading = false;
          }, 1000);
        }
      }
    },
  },
  mounted() {
    this.handleChargingOptionsComponent();
    this.handleDefaultMessageTable();
  },
};
